import React, { useCallback, useRef } from 'react';

import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

interface IProps {
  buttonColor?: string;
  disabled?: boolean;
  className?: string;
}

export default function ToggleVideoButton({ buttonColor = 'white', className, disabled }: IProps) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button
      className={className}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || disabled}
      startIcon={isVideoEnabled ? <VideoOnIcon color={buttonColor} /> : <VideoOffIcon color={buttonColor} />}
      style={{ color: buttonColor }}
    >
      {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Fechar Câmera' : 'Abrir Câmera'}
    </Button>
  );
}
