import React from 'react';

import Button from '@material-ui/core/Button';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

interface IProps {
  buttonColor?: string;
  disabled?: boolean;
  className?: string;
}

export default function ToggleAudioButton({ buttonColor = 'white', className, disabled }: IProps) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <Button
      className={className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || disabled}
      startIcon={isAudioEnabled ? <MicIcon color={buttonColor} /> : <MicOffIcon color={buttonColor} />}
      data-cy-audio-toggle
      style={{ color: buttonColor }}
    >
      {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Fechar Microfone' : 'Abrir Microfone'}
    </Button>
  );
}
