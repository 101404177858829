export function returnUrlLogin() {
  if (window.location.href.includes('test')) return 'https://test.signumweb.com.br';
  return 'https://app.signumweb.com.br';
}

export function returnBaseUrlBackend() {
  if (window.location.href.includes('test')) return 'https://apitest.signumweb.com.br';
  else if (window.location.href.includes('sala')) return 'https://api.signumweb.com.br';
  return 'http://127.0.0.1:3333';
}
